(function (exports) {
    var configHerbalifeHEC = {
        token: "5F0EDC1E-0C9D-5615-9E43-A5AA7CB0F8C1",
        host: "http-inputs-hrbl.splunkcloud.com"
    };

    var splunkLogger = new splunk.SplunkLogger(configHerbalifeHEC);

    splunkLogger.eventFormatter = function (message, severity) {
        var event = {
            message: message,
            severity: severity,
            userAgent: navigator.userAgent,
            platform: navigator.platform,
            url: location.href,
            language: navigator.language
        }

        return event;
    }

    function wrapLog(msg, severity, appSource) {
        var payload = {
            message: msg,
            severity: severity,
            metadata: {
                host: window.location.host,
                source: getSource()
            }
        };
        return payload;
    }

    function getSource() {
        var result = "";
        var url = window.location.href;
        var source = url.split("/");
        var posibleLocale = source[3];
        if (source[3] === "") {
            result = "Root";
        } else {
            if (posibleLocale[2] === "-") {
                result = source[4];
            } else {
                result = source[3];
            }

        }
        
        return result;
    }

    function error(msg, source) { log(msg, splunkLogger.levels.ERROR, source); }

    function warn(msg, source) { log(msg, splunkLogger.levels.WARN, source); }

    function info(msg, source) { log(msg, splunkLogger.levels.INFO, source); }

    function debug(msg, source) { log(msg, splunkLogger.levels.DEBUG, source); }

    function log(msg, severity, source) {
        var wrapped = wrapLog(msg, severity, source);

        console.log("Splunk", severity, msg);
        splunkLogger.send(wrapped, function (err, resp, body) {
            if (err) {
                console.log("error:", err);
            }
            // If successful, body will be { text: 'Success', code: 0 }
            console.log("body", body);
        });
    }

    // Log all unhandled exceptions
    TraceKit.report.subscribe(function (stackInfo) {
        error(stackInfo, SplunkConfig.application || "undefined");
    });

    exports.SpluLogger = {
        error: error,
        warn: warn,
        info: info,
        debug: debug
    };
})(typeof exports === 'undefined' ? this : exports);